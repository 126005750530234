import FormDialog from '@components/form/dialog_v2'
import LoadingPopup from '@components/form/LoadingDialog'
import FormSignaturePad from '@components/form/signaturePad'
import FormField from '@components/form/field'
import { useSnackbar } from 'notistack'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import { styled, SxProps, Theme, useTheme } from '@mui/material/styles'
import { FormPartComponents, KeyValPair } from '@models/Common'
import getErrMsg from '@utils/getErrMsg'

import {
  Box,
  Typography,
  Divider,
  Button,
  BoxProps,
  Grid,
  Stack,
  Autocomplete,
  TextField,
  IconButton,
  useMediaQuery,
  Drawer,
  Paper,
  CircularProgress,
  InputBase,
  StepIconProps,
  Step,
  StepLabel,
  Stepper,
  StepConnector,
  stepConnectorClasses,
} from '@mui/material'
import { FormContext } from '@providers/formStateProvider'
import { useState, useContext, useRef, Fragment, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import commonStyles from '@styles/common.module.scss'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { Comment, FormHistory, FormStatusEnum, NotifyList } from '@services/model/form/form.model'
import { GlobalContext, GlobalStateProps } from '@providers/globalStore'
import GetValueWithKey from '@utils/getValueWithKey'
import moment from 'moment'
import ChatBubbleIcon from '@mui/icons-material/ChatBubble'
import avatar from '../assets/img/dummyAvatar.png'
import useAPIFetch from './useAPIFetch'
import DialogButton from '@components/button/DialogButton'
import FormCheckbox from '@components/form/checkbox'
import { useFormContext, useWatch } from 'react-hook-form'
import Debugger from '@components/Debugger'
import AdbIcon from '@mui/icons-material/Adb'
import { i18nAlphabetNS } from '@i18n/namespaces'

function RoleItem(props: BoxProps) {
  const { sx, ...other } = props
  return (
    <Box
      sx={{
        mr: 1,
        p: 1,
        border: '1px solid',
        borderRadius: 2,
        fontSize: '0.875rem',
        fontWeight: '700',
        ...sx,
      }}
      {...other}
    />
  )
}

const StyledBox = styled(Box)(({ theme }) => ({
  display: 'block',
  margin: theme.spacing(1),
  paddingTop: theme.spacing(8),
  paddingBottom: theme.spacing(1),
  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}))

const FormTitleTypography = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(1),
  padding: theme.spacing(2),
  paddingBottom: theme.spacing(0),
}))

const PartTitleTypography = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(3),
  marginRight: theme.spacing(8),
  marginLeft: theme.spacing(8),
  marginTop: theme.spacing(1),
}))

const StyledDivider = styled(Divider)(({ theme }) => ({
  paddingLeft: theme.spacing(6),
  paddingRight: theme.spacing(6),
  '&::before, &::after': {
    borderColor: 'black',
  },
}))

const ButtonGroupBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  pt: 2,
  paddingLeft: theme.spacing(6),
  paddingRight: theme.spacing(6),
}))

const SwitcherButton = styled(Button)(() => ({
  color: '#000000',
  '&:hover': {
    backgroundColor: '#e6e6e6',
  },
  '&:disabled': {
    opacity: 0.5,
  },
}))

const CommentItemTitle = styled(Box)<{ backgroundColor?: string }>(({ backgroundColor }) => ({
  minHeight: '29px',
  paddingLeft: '20px',
  paddingRight: '20px',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  backgroundColor: backgroundColor ?? '#fff',
  borderTopLeftRadius: '5px',
  borderTopRightRadius: '5px',
  fontWeight: 'bold',
}))

const CommentItemContent = styled(Grid)({
  minHeight: '68px',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  borderBottomLeftRadius: '5px',
  borderBottomRightRadius: '5px',
})

type DialogContextProps = {
  isOpen: boolean
  title?: JSX.Element
  toolbarStyle?: SxProps<Theme>
  children?: JSX.Element
  buttons?: JSX.Element
}

type useFormSwitcherProps = {
  control?: any
  trigger?: any
  onExportDocument?: ((event: any) => void) | undefined
  disableComment?: boolean | undefined
  title: string
  components: FormPartComponents[]
  startStep?: number
  hideSubmissionComment?: boolean
  formOnSubmit?: (
    event: any,
    isReject: boolean,
    isReturn: boolean,
    notifyList: string[],
    assignToList: string[],
    signatureBase64?: string,
    submissionComment?: string,
  ) => Promise<void>

  formOnSave?: (event: any) => Promise<void>
  formOnDelete?: (event: any) => Promise<void>
  formOnConfirmSave?: (event: any) => Promise<void>
  formOnLoadNotifyList?: (isReject: boolean, isReturn: boolean) => Promise<NotifyList>
  formOnLoadCommentList?: () => Promise<Comment[]>
  formOnSubmitComment?: (comment: string) => Promise<void>
  formOnLoadFormHistoryList?: () => Promise<FormHistory[]>

  isLoading?: boolean
  disableSave?: boolean
  disableDelete?: boolean
  disableSaveBtn?: boolean
  disableSaveOnStep?: number
  formOnNext?: (event: any, step: number) => Promise<void>
  formOnBack?: (event?: any) => Promise<void>

  navigateBack?: (event: any) => void
  onSubFormComplete?: () => void
  returnRequired?: boolean
  approveRequired?: boolean
  endOfFlow?: boolean

  isMounted?: boolean

  customEndDialog?: any
  customCompleteLabel?: string
  customNextLabel?: any
  minAssignUser?: number
}

const CommentInput = (props: { formOnSubmitComment?: (comment: string) => Promise<void> }) => {
  const { t } = useTranslation(['common', 'forms'])

  const [comment, setComment] = useState<string>('')

  const handleCommentChange = (event: any) => {
    setComment(event.target.value)
  }

  const handleCommentSubmit = async (event: any) => {
    event.preventDefault()
    if (props.formOnSubmitComment) {
      await props.formOnSubmitComment(comment)
      setComment('')
    }
  }

  return (
    <Box
      component="form"
      sx={{ display: 'flex', marginTop: 1, marginBottom: 0 }}
      onSubmit={handleCommentSubmit}>
      <InputBase
        id="comment-input"
        sx={{
          width: '100%',
          padding: 1,
        }}
        placeholder={t('common:components.formSwitcher.commentPlaceholder')}
        onChange={handleCommentChange}
        value={comment}
      />
      <IconButton type="submit">
        <ChatBubbleIcon />
      </IconButton>
    </Box>
  )
}

const CommentDrawer = (props: {
  refContainer?: Element | (() => Element | null) | null | undefined
  commentDrawerOpen: boolean
  handleCommentDrawerClick: () => void
  globalState: GlobalStateProps
  formOnLoadCommentList?: () => Promise<Comment[]>
  formOnSubmitComment?: (comment: string) => Promise<void>
  width: string | number
}) => {
  const { setRequest, isLoading } = useAPIFetch()

  const [comments, setComments] = useState<Comment[]>([])

  const { t } = useTranslation()
  useEffect(() => {
    if (props.commentDrawerOpen) {
      reloadComments()
    }
  }, [props.commentDrawerOpen])

  const reloadComments = () => {
    setRequest({
      callback: async () => {
        if (props.formOnLoadCommentList) {
          await props.formOnLoadCommentList().then((commentList) => {
            setComments(commentList.filter((x) => x.status))
          })
        }
      },
    })
  }

  return (
    <Drawer
      anchor="right"
      open={props.commentDrawerOpen}
      onClose={props.handleCommentDrawerClick}
      PaperProps={{ style: { position: 'absolute', boxShadow: 'none', zIndex: 99 } }}
      BackdropProps={{
        style: { position: 'absolute', backgroundColor: 'transparent', zIndex: 99 },
      }}
      ModalProps={{
        container: props.refContainer,
        style: { position: 'absolute', zIndex: 99 },
        disableEnforceFocus: true,
        keepMounted: true,
      }}>
      <Fragment>
        {props.commentDrawerOpen && (
          <Box
            sx={{
              width: props.width,
              height: '100%',
              padding: '60px 8px 8px',
            }}>
            <Box
              sx={{
                height: '100%',
                gap: 1,
                display: 'flex',
                flexDirection: 'column',
              }}>
              <Box
                sx={{
                  padding: 1,
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 'bold',
                  }}>
                  {t('Comments')}
                </Typography>
                <Box flex={1} />
                <IconButton onClick={props.handleCommentDrawerClick}>
                  <ArrowForwardIosIcon />
                </IconButton>
              </Box>
              <Divider />
              <Paper
                sx={{
                  flex: 1,
                  overflowX: 'hidden',
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                <Box
                  sx={{
                    overflowY: 'scroll',
                    padding: 3,
                    gap: 2,
                    flex: 1,
                  }}>
                  {isLoading && (
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}>
                      <CircularProgress />
                    </Box>
                  )}
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1,
                    }}>
                    {comments.map((c) => (
                      <Paper
                        key={`drawer-comment-${c.id}`}
                        sx={{
                          padding: 2,
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          width: '100%',
                        }}>
                        <Box
                          component="img"
                          alt="Varadise"
                          src={avatar}
                          sx={{ width: '2.8rem', height: '2.8rem', borderRadius: '50%' }}
                        />
                        <Box sx={{ width: '-webkit-fill-available' }}>
                          <Box sx={{ display: 'flex' }}>
                            <Typography
                              sx={{
                                fontSize: 9,
                              }}>
                              {GetValueWithKey(c.createdBy, props.globalState.userMetaList ?? [])}
                            </Typography>
                            <Box sx={{ flex: 1 }} />
                            <Typography
                              sx={{
                                fontSize: 9,
                              }}>
                              {moment(c.createdAt).format()}
                            </Typography>
                          </Box>
                          <Typography>{c.comment}</Typography>
                        </Box>
                      </Paper>
                    ))}
                  </Box>
                </Box>
                <CommentInput
                  formOnSubmitComment={async (comment: string) => {
                    if (props.formOnSubmitComment) {
                      await props.formOnSubmitComment(comment)
                      reloadComments()
                    }
                  }}
                />
              </Paper>
            </Box>
          </Box>
        )}
      </Fragment>
    </Drawer>
  )
}

const ContentDrawer = (props: {
  refContainer?: Element | (() => Element | null) | null | undefined
  contentDrawerOpen: boolean
  handleContentDrawerClick: () => void
  contents: {
    title: string
    disabled?: boolean
  }[]
  onContentClick: (contentIndex: number) => void
  currentStep: number
  width: number | string
}) => {
  const { t } = useTranslation()
  let partLetterNumRef = 64

  return (
    <Drawer
      anchor="right"
      open={props.contentDrawerOpen}
      onClose={props.handleContentDrawerClick}
      PaperProps={{ style: { position: 'absolute', boxShadow: 'none', zIndex: 99 } }}
      BackdropProps={{
        style: { position: 'absolute', backgroundColor: 'transparent', zIndex: 99 },
      }}
      ModalProps={{
        container: props.refContainer,
        style: { position: 'absolute', zIndex: 99 },
        disableEnforceFocus: true,
        keepMounted: true,
      }}>
      <Fragment>
        {props.contentDrawerOpen && (
          <Box
            sx={{
              width: props.width,
              height: '100%',
              padding: '60px 8px 8px',
            }}>
            <Box
              sx={{
                height: '100%',
                gap: 1,
                display: 'flex',
                flexDirection: 'column',
              }}>
              <Box
                sx={{
                  padding: 1,
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 'bold',
                  }}>
                  {t('Contents')}
                </Typography>
                <Box flex={1} />
                <IconButton onClick={props.handleContentDrawerClick}>
                  <ArrowForwardIosIcon />
                </IconButton>
              </Box>
              <Divider />
              <Paper
                sx={{
                  flex: 1,
                  overflow: 'hidden',
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                <Box
                  sx={{
                    overflow: 'scroll',
                    padding: 3,
                    gap: 2,
                  }}>
                  {props.contents.map((content, cIndex) => {
                    if (!content.disabled) {
                      partLetterNumRef = partLetterNumRef + 1
                    }

                    return (
                      <Box
                        key={`drawer-content-${cIndex}`}
                        sx={{
                          padding: 1,
                          cursor: content.disabled ? 'inherit' : 'pointer',
                          ...(props.currentStep === cIndex && {
                            backgroundColor: '#f2f2f2',
                          }),
                          ...(content.disabled && {
                            color: '#f2f2f2',
                          }),
                        }}
                        onClick={() => {
                          if (!content.disabled) {
                            props.onContentClick(cIndex)
                          }
                        }}>
                        {(content.disabled
                          ? ''
                          : t(String.fromCharCode(partLetterNumRef), { ns: i18nAlphabetNS }) +
                            ' / ') + content.title}
                      </Box>
                    )
                  })}
                </Box>
              </Paper>
            </Box>
          </Box>
        )}
      </Fragment>
    </Drawer>
  )
}

const FormHistoryDrawer = (props: {
  refContainer?: Element | (() => Element | null) | null | undefined
  formHistoryDrawerOpen: boolean
  handleFormHistoryDrawerClick: () => void
  formHistories: FormHistory[]
  width: number | string
  globalState: GlobalStateProps
}) => {
  const { t } = useTranslation()

  const FormStepConnector = styled(StepConnector)(({ theme }) => ({
    [`& .${stepConnectorClasses.line}`]: {
      margin: 'auto auto auto 12.5px',
    },
  }))

  const FormStepIconRoot = styled('div')<{
    state: { first?: boolean }
  }>(({ state }) => ({
    zIndex: 1,
    backgroundColor: '#e6e6e6',
    width: state.first ? 50 : 30,
    height: state.first ? 50 : 30,
    marginLeft: state.first ? 0 : 10,
    marginRight: state.first ? 0 : 10,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  }))

  const FormStepComponent = (props: StepIconProps) => {
    return (
      <FormStepIconRoot state={{ first: String(props.icon) === '1' }}>
        {String(props.icon)}
      </FormStepIconRoot>
    )
  }

  return (
    <Drawer
      anchor="right"
      open={props.formHistoryDrawerOpen}
      onClose={props.handleFormHistoryDrawerClick}
      PaperProps={{ style: { position: 'absolute', boxShadow: 'none', zIndex: 99 } }}
      BackdropProps={{
        style: { position: 'absolute', backgroundColor: 'transparent', zIndex: 99 },
      }}
      ModalProps={{
        container: props.refContainer,
        style: { position: 'absolute', zIndex: 99 },
        disableEnforceFocus: true,
        keepMounted: true,
      }}>
      <Fragment>
        {props.formHistoryDrawerOpen && (
          <Box
            sx={{
              width: props.width,
              height: '100%',
              padding: '60px 8px 8px',
            }}>
            <Box
              sx={{
                height: '100%',
                gap: 1,
                display: 'flex',
                flexDirection: 'column',
              }}>
              <Box
                sx={{
                  padding: 1,
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 'bold',
                  }}>
                  {t('common:buttons.histories')}
                </Typography>
                <Box flex={1} />
                <IconButton onClick={props.handleFormHistoryDrawerClick}>
                  <ArrowForwardIosIcon />
                </IconButton>
              </Box>
              <Divider />
              <Paper
                sx={{
                  flex: 1,
                  overflow: 'hidden',
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                <Box
                  sx={{
                    overflow: 'scroll',
                    padding: 3,
                    gap: 2,
                  }}>
                  <Stepper orientation="vertical" connector={<FormStepConnector />}>
                    {props.formHistories.map((formHistory, fhIndex) => (
                      <Step key={`drawer-form-history-${fhIndex}`}>
                        <StepLabel StepIconComponent={FormStepComponent}>
                          <Box>
                            <Typography
                              sx={{
                                color: 'black',
                              }}>
                              {formHistory.formStatus}
                            </Typography>
                            <Typography sx={{ color: 'black', fontSize: 9 }}>
                              {`${GetValueWithKey(
                                formHistory.actionBy,
                                props.globalState.userMetaList ?? [],
                              )}`}
                            </Typography>
                            <Typography sx={{ color: 'black', fontSize: 9 }}>
                              {moment(formHistory.actionAt).format()}
                            </Typography>
                          </Box>
                        </StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
              </Paper>
            </Box>
          </Box>
        )}
      </Fragment>
    </Drawer>
  )
}

const useFormSwitcher = function (props: useFormSwitcherProps): {
  FormSwitcher: () => JSX.Element
  handleOnComplete: () => void
  handleNext: (event: any) => Promise<void>
  handleBack: (event: any) => Promise<void>
  activeStep: number
} {
  const { state: globalState } = useContext(GlobalContext)
  const theme = useTheme()
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'))

  const data = props.control ? useWatch({ control: props.control }) : { error: 'missing control' }
 
  const isSave = props.title==='Chit Record' && data.baseForm.formStatus === FormStatusEnum.DailyRecordSummaryItemReview

  // startStep checking
  const partLetterNumRef = useRef<number>(65)
  const InitDialogContent: DialogContextProps = {
    isOpen: false,
    title: <></>,
  }

  const [dialogContext, setDialogContext] = useState<DialogContextProps>(InitDialogContent)

  const { dispatch } = useContext(FormContext)
  const { t } = useTranslation()
  const navigate = useNavigate()

  let initStep = 0
  if (props.startStep) {
    if (props.startStep >= 0 && props.startStep < props.components.length) {
      initStep = props.startStep
    } else {
      console.error('startStep from props must be within range of components')
    }
  }
  const [activeStep, setActiveStep] = useState<number>(initStep)

  useEffect(() => {
    partLetterNumRef.current += initStep
  }, [])

  useEffect(() => {
    if (props.isMounted) {
      initStep = props.components?.findIndex((x, idx) => x.disabled !== true && idx >= initStep)
      if (initStep >= 0) {
        setActiveStep(initStep)
      } else {
        setActiveStep(0)
      }
    }
  }, [props.isMounted])

  const handleNext = async (event: any) => {
    const nextStep = props.components.findIndex((x, idx) => !x.disabled && idx > activeStep)
    if (nextStep < 0 || nextStep >= props.components.length) {
      handleOnComplete()
    } else {
      let preventNextCount: any = 0
      const customOnNext = props.components[activeStep].onNext
      if (customOnNext) {
        let count = await customOnNext(event)
        if (typeof count === 'number' && count > 0) {
          console.debug('customOnNext', count)
          preventNextCount += count
        }
      }

      if (props.formOnNext) {
        let count = await props.formOnNext(event, activeStep)
        if (typeof count === 'number' && count > 0) {
          preventNextCount += count
        }
      }

      if (preventNextCount !== 0) return
      partLetterNumRef.current += 1
      setActiveStep(nextStep)
      window.scrollTo(0, 0)
    }
  }

  const handleBack = async (event?: any) => {
    let prevStep = -1
    for (let i = activeStep - 1; i >= 0; i--) {
      if (!props.components[i].disabled) {
        prevStep = i
        break
      }
    }
    if (prevStep >= 0) {
      if (props.formOnBack) {
        await props.formOnBack(event)
      }
      setActiveStep(prevStep)
      window.scrollTo(0, 0)
      partLetterNumRef.current -= 1
    }
  }

  function IconItem(iconProps: BoxProps) {
    const { sx, ...other } = iconProps
    return (
      <Box
        sx={{
          m: 1,
          color: 'dark',
          border: '1px solid',
          borderColor: 'transparent',
          fontSize: '0.875rem',
          ...sx,
        }}
        {...other}
      />
    )
  }

  const handleOnDelete = (event: any) => {
    if (props.formOnDelete) props.formOnDelete(event)
  }

  const handleDiscard = (event: any) => {
    if (props.navigateBack) {
      dispatch({ type: 'clear' })
      props.navigateBack(event)
    } else {
      dispatch({ type: 'clear' })
      const pathList = window.location.pathname.split('/')
      navigate(`/${pathList[1]}/all-records`)
    }
  }

  const handleDialogClose = () => {
    setDialogContext(InitDialogContent)
  }
  const handleDialogConfirm = () => {
    props?.customEndDialog?.handleConfirm()
    handleDialogClose()
  }
  const handleBackBtn = () => {
    setDialogContext({
      isOpen: true,
      title: (
        <Typography variant="body1">
          {t('common:components.formSwitcher.backBtnWarning')}
        </Typography>
      ),
      children: <Grid></Grid>,
      buttons: (
        <Stack direction="row" spacing={2}>
          <DialogButton
            disabled={props.disableSave || props.disableSaveBtn || props.endOfFlow}
            onClick={(event: any) => {
              if (props.onSubFormComplete) {
                handleOnComplete()
              } else if (props.formOnConfirmSave) {
                props.formOnConfirmSave!(event)
              } else if (props.formOnSave) {
                props.formOnSave(event)
                setDialogContext({
                  isOpen: false,
                })
                handleDiscard(event) // CIC TID-1769 save and go back to list
              }
            }}>
            {t('common:buttons.save')}
          </DialogButton>
          <DialogButton onClick={handleDiscard}>{t('common:buttons.discard')}</DialogButton>
          <DialogButton onClick={handleDialogClose}>{t('common:buttons.cancel')}</DialogButton>
        </Stack>
      ),
    })
  }

  const selectedNotifyUserListRef = useRef<KeyValPair[]>([])
  const selectedAssignToUserListRef = useRef<KeyValPair[]>([])
  const [selectedAssignToUserList, setSelectedAssignToUserList] = useState<any[]>([])
  const signatureBase64Ref = useRef<string | undefined>()
  const commentInApproveReject = useRef<string | undefined>()

  const handleOnApprove = (event: any) => {
    changeContextForConfirmation(
      t('common:buttons.approve'),
      'green',
      false,
      false,
      props.hideSubmissionComment ? false : true,
      props.formOnSubmit,
    )
  }

  const handleOnReject = (event: any) => {
    changeContextForConfirmation(
      t('common:buttons.reject'),
      'red',
      true,
      false,
      props.hideSubmissionComment ? false : true,
      props.formOnSubmit,
    )
  }

  const handleOnReturn = (event: any) => {
    changeContextForConfirmation(
      t('common:buttons.return'),
      'red',
      false,
      true,
      props.hideSubmissionComment ? false : true,
      props.formOnSubmit,
    )
  }

  const { enqueueSnackbar } = useSnackbar()
  const [assignToMissing, setAssignToMissing] = useState<boolean>(false)

  const requireMinAssignUser = !!props.minAssignUser
  const minAssignUser = props.minAssignUser || 0

  const changeContextForConfirmation = async (
    headerTitle: string,
    headerColor: string,
    isReject: boolean,
    isReturn: boolean,
    requireComment: boolean,
    onConfirmCallback?: (
      event: any,
      isReject: boolean,
      isReturn: boolean,
      notifyList: string[],
      assignToList: string[],
      signatureBase64?: string,
      submissionComment?: string,
    ) => Promise<void>,
  ) => {
    let defaultNotifyList: NotifyList = { roles: [], users: [] }
    let hasError = false
    if (props.formOnLoadNotifyList) {
      await props
        .formOnLoadNotifyList(isReject, isReturn)
        .then((x) => {
          defaultNotifyList = x
        })
        .catch((err) => {
          enqueueSnackbar(getErrMsg(t, err), {
            variant: 'error',
          })
          hasError = true
        })
    }

    if (hasError) return

    let defaultNotifyUserList =
      globalState.userMetaList?.filter((x) => {
        return defaultNotifyList?.users?.includes(x.key)
      }) || []

    selectedNotifyUserListRef.current = defaultNotifyUserList

    setDialogContext({
      isOpen: true,
      toolbarStyle: headerColor ? { backgroundColor: headerColor, color: 'white' } : undefined,
      title: headerTitle ? <Typography variant="body1">{headerTitle}</Typography> : undefined,
      children: (
        <Grid container sx={{ alignItems: 'center' }}>
          <Grid item xs={matchDownMd ? 12 : 2} sx={{ p: 1 }}>
            {t('common:components.formSwitcher.submitTo')}
          </Grid>

          <Grid item xs={matchDownMd ? 12 : 10} sx={{ p: 1 }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                bgcolor: 'background.paper',
                borderRadius: 1,
              }}>
              {(
                globalState.roleMetaList?.filter((x) => {
                  return defaultNotifyList?.roles?.includes(x.key)
                }) || []
              )?.map((x, idx) => {
                return <RoleItem key={`ri_${idx}`}>{x.value}</RoleItem>
              })}
            </Box>
          </Grid>

          <Grid item xs={matchDownMd ? 12 : 2} sx={{ p: 1 }}>
            {t('common:components.formSwitcher.assignTo')}
            {requireMinAssignUser && <span style={{ color: 'red' }}>*</span>}
          </Grid>
          <Grid item xs={matchDownMd ? 12 : 10} sx={{ p: 1 }}>
            <Autocomplete
              fullWidth
              disablePortal
              multiple
              defaultValue={[]}
              getOptionLabel={(option: KeyValPair) => {
                return t(option.value)
              }}
              options={defaultNotifyUserList}
              onChange={(event: any, newValue: KeyValPair[]) => {
                selectedAssignToUserListRef.current = newValue
              }}
              {...(matchDownMd && {
                sx: {
                  height: '100px',
                  overflowY: 'auto',
                  '&.MuiFormControl-root': {
                    height: 'inherit',
                  },
                  '::-webkit-scrollbar': {
                    width: '20px',
                  },
                },
              })}
              renderInput={(params) => {
                const { InputProps, inputProps, ...restParams } = params
                const { startAdornment, ...restInputProps } = InputProps
                return (
                  <TextField
                    sx={assignToMissing ? { border: '1px solid red', borderRadius: '5px' } : {}}
                    {...restParams}
                    inputProps={{
                      ...inputProps,
                      value:
                        typeof inputProps?.value === 'string'
                          ? t(inputProps?.value)
                          : inputProps?.value,
                    }}
                    InputProps={{
                      ...restInputProps,
                      startAdornment: (
                        <div
                          style={{
                            maxHeight: '250px',
                            overflowY: 'auto',
                          }}>
                          {startAdornment}
                        </div>
                      ),
                    }}
                  />
                )
              }}
            />
          </Grid>

          <Grid item xs={matchDownMd ? 12 : 2} sx={{ p: 1 }}>
            {t('common:components.formSwitcher.notify')}
          </Grid>
          <Grid item xs={matchDownMd ? 12 : 10} sx={{ p: 1 }}>
            <Autocomplete
              fullWidth
              disablePortal
              multiple
              defaultValue={defaultNotifyUserList}
              getOptionLabel={(option: KeyValPair) => {
                return t(option.value)
              }}
              options={
                globalState.userMetaList?.filter((x) => {
                  return globalState?.currentProject?.projectUsers?.includes(x.key)
                }) || []
              }
              onChange={(event: any, newValue: KeyValPair[]) => {
                selectedNotifyUserListRef.current = newValue
              }}
              {...(matchDownMd && {
                sx: {
                  height: '100px',
                  overflowY: 'auto',
                  '&.MuiFormControl-root': {
                    height: 'inherit',
                  },
                  '::-webkit-scrollbar': {
                    width: '20px',
                  },
                },
              })}
              renderInput={(params) => {
                const { InputProps, inputProps, ...restParams } = params
                const { startAdornment, ...restInputProps } = InputProps
                return (
                  <TextField
                    {...restParams}
                    inputProps={{
                      ...inputProps,
                      value:
                        typeof inputProps?.value === 'string'
                          ? t(inputProps?.value)
                          : inputProps?.value,
                    }}
                    InputProps={{
                      ...restInputProps,
                      startAdornment: (
                        <div
                          style={{
                            maxHeight: '250px',
                            overflowY: 'auto',
                          }}>
                          {startAdornment}
                        </div>
                      ),
                    }}
                  />
                )
              }}
            />
          </Grid>

          {requireComment ? (
            <>
              <Grid item xs={matchDownMd ? 12 : 2} sx={{ p: 1 }}>
                {t('common:components.formSwitcher.comment')}
              </Grid>
              <Grid item xs={matchDownMd ? 12 : 10} sx={{ p: 1 }}>
                <Box
                  component="form"
                  sx={{
                    display: 'flex',
                    marginTop: 1,
                    marginBottom: 0,
                    border: '1px solid #c4c4c4',
                  }}>
                  <InputBase
                    id="comment-input"
                    sx={{
                      width: '100%',
                      padding: 1,
                    }}
                    placeholder={t('common:components.formSwitcher.commentPlaceholder')}
                    onChange={(event) => {
                      commentInApproveReject.current = event.target.value
                    }}
                  />
                </Box>
              </Grid>
            </>
          ) : (
            <></>
          )}

          <Grid item xs={matchDownMd ? 12 : 2} sx={{ p: 1 }}>
            {t('common:components.formSwitcher.signature')}
            <span style={{ color: 'red' }}>*</span>
          </Grid>
          <Grid
            item
            xs={matchDownMd ? 12 : 10}
            sx={{ p: 1, border: '1px solid', overflow: 'hidden' }}>
            <FormSignaturePad
              sx={{ width: '650px' }}
              onChange={(newValue: string) => {
                signatureBase64Ref.current = newValue
              }}
            />
          </Grid>
        </Grid>
      ),
      buttons: (
        <Stack {...(!matchDownMd && { direction: 'row' })} spacing={2}>
          <DialogButton
            disabled={props.disableSave}
            onClick={async (event) => {
              if (
                requireMinAssignUser &&
                (!selectedAssignToUserListRef.current ||
                  selectedAssignToUserListRef?.current.length < minAssignUser)
              ) {
                enqueueSnackbar(t('Please at least select one person in the Assign To'), {
                  variant: 'error',
                })
              } else {
                if (onConfirmCallback) {
                  await onConfirmCallback(
                    event,
                    isReject,
                    isReturn,
                    selectedNotifyUserListRef.current.map((x) => x.key),
                    selectedAssignToUserListRef.current.map((x) => x.key),
                    signatureBase64Ref.current,
                    commentInApproveReject.current,
                  )
                  handleDialogClose()
                }
                // todo: pending test
                if (
                  props.formOnSubmitComment &&
                  commentInApproveReject.current !== undefined &&
                  commentInApproveReject.current !== ''
                ) {
                  props.formOnSubmitComment(commentInApproveReject.current)
                }
              }
            }}>
            {t('common:buttons.confirm')}
          </DialogButton>
          <DialogButton onClick={handleDialogClose}>{t('common:buttons.cancel')}</DialogButton>
        </Stack>
      ),
    })
  }
  const [disableBtn, setDisableBtn] = useState(false)
  // const isSave = props.title==='Chit Record'

  const handleOnComplete = () => {
    if (props.onSubFormComplete) {
      props.onSubFormComplete()
      return
    }

    signatureBase64Ref.current = undefined

    // if customEndDialog is defined, use it

    if (props.customEndDialog) {
      setDialogContext({
        isOpen: true,
        title: (
          <Typography variant="body1">
            {t('common:components.formSwitcher.confirmation')}
          </Typography>
        ),
        children: props.customEndDialog.content,
        buttons: (
          <Stack direction="row" spacing={2}>
            <DialogButton onClick={handleDialogConfirm} disabled={disableBtn}>
              {t('common:buttons.confirm')}
            </DialogButton>
            <DialogButton onClick={handleDialogClose}>{t('common:buttons.cancel')}</DialogButton>
          </Stack>
        ),
      })
    } else if (props.approveRequired === true || props.returnRequired === true) {
      setDialogContext({
        isOpen: true,
        title: <Typography variant="body1">{t('common:messages.proceedSubmission')}</Typography>,
        children: <Grid></Grid>,
        buttons: (
          <Stack direction="row" spacing={2}>
            {props.approveRequired === true && (
              <>
                <DialogButton onClick={handleOnApprove} disabled={props.disableSave}>
                  {isSave?t('common:buttons.save'):t('common:buttons.approve')}
                </DialogButton>
                {!isSave&&<DialogButton onClick={handleOnReject} disabled={props.disableSave}>
                  {t('common:buttons.reject')}
                </DialogButton>}
              </>
            )}
            {props.returnRequired === true && (
              <DialogButton onClick={handleOnReturn} disabled={props.disableSave}>
                {t('common:buttons.return')}
              </DialogButton>
            )}
            <DialogButton onClick={handleDialogClose} disabled={props.disableSave}>
              {t('common:buttons.cancel')}
            </DialogButton>
          </Stack>
        ),
      })
    } else {
      changeContextForConfirmation(
        '',
        '',
        false,
        false,
        props.hideSubmissionComment ? false : true,
        props.formOnSubmit,
      )
    }
  }

  const handleDelete = (event: any) => {
    if (props.onSubFormComplete) {
      handleDiscard(event)
    } else {
      setDialogContext({
        isOpen: true,
        title: (
          <Typography variant="body1">
            {t('common:components.formSwitcher.deleteWarning')}
          </Typography>
        ),
        children: <Grid></Grid>,
        buttons: (
          <Stack direction="row" spacing={2}>
            <DialogButton onClick={handleOnDelete}>
              {t('common:components.binarySelect.yes')}
            </DialogButton>
            <DialogButton onClick={handleDialogClose}>
              {t('common:components.binarySelect.no')}
            </DialogButton>
          </Stack>
        ),
      })
    }
  }

  const containerRef = useRef<HTMLDivElement>(null)
  const [commentDrawerOpen, setCommentDrawerOpen] = useState<boolean>(false)

  const handleCommentDrawerClick = () => {
    setCommentDrawerOpen((open) => !open)
    setContentDrawerOpen(false)
    setFormHistoryDrawerOpen(false)
  }
  //#endregion

  //#region contents
  const [contentDrawerOpen, setContentDrawerOpen] = useState<boolean>(false)
  const handleContentDrawerClick = () => {
    setContentDrawerOpen((open) => !open)
    setCommentDrawerOpen(false)
    setFormHistoryDrawerOpen(false)
  }
  //#endregion

  //#region form history
  const [formHistoryDrawerOpen, setFormHistoryDrawerOpen] = useState<boolean>(false)
  const [formHistories, setFormHistories] = useState<FormHistory[]>([])
  const reloadFormHistories = () => {
    if (props.formOnLoadFormHistoryList) {
      props
        .formOnLoadFormHistoryList()
        .then((formHistoryList) => {
          setFormHistories(formHistoryList.filter((x) => x.status))
        })
        .catch((err) => {
          console.error('Error getting form histories from form', err)
          setFormHistories([])
        })
    } else {
      setFormHistories([])
    }
  }

  const handleFormHistoryDrawerClick = () => {
    setFormHistoryDrawerOpen((open) => !open)
    setCommentDrawerOpen(false)
    setContentDrawerOpen(false)
    if (formHistoryDrawerOpen) {
      setFormHistories([])
    } else {
      reloadFormHistories()
    }
  }
  //#endregion

  const CommentItem = () => {
    const [commentDrawerOpen, setCommentDrawerOpen] = useState<boolean>(false)

    const handleCommentDrawerClick = () => {
      setCommentDrawerOpen((open) => !open)
    }

    const CommentDrawer = (props: {
      refContainer?: Element | (() => Element | null) | null | undefined
      commentDrawerOpen: boolean
      handleCommentDrawerClick: () => void
      globalState: GlobalStateProps
      formOnLoadCommentList?: () => Promise<Comment[]>
      formOnSubmitComment?: (comment: string) => Promise<void>
      width: string | number
    }) => {
      const { setRequest, isLoading } = useAPIFetch()

      const [comments, setComments] = useState<Comment[]>([])

      const { t } = useTranslation()

      useEffect(() => {
        if (props.commentDrawerOpen) {
          reloadComments()
        }
      }, [props.commentDrawerOpen])

      const reloadComments = () => {
        setRequest({
          callback: async () => {
            if (props.formOnLoadCommentList) {
              await props.formOnLoadCommentList().then((commentList) => {
                setComments(commentList.filter((x) => x.status))
              })
            }
          },
        })
      }

      return (
        <Drawer
          anchor="right"
          open={props.commentDrawerOpen}
          onClose={props.handleCommentDrawerClick}
          PaperProps={{ style: { position: 'absolute', boxShadow: 'none', zIndex: 99 } }}
          BackdropProps={{
            style: { position: 'absolute', backgroundColor: 'transparent', zIndex: 99 },
          }}
          ModalProps={{
            container: props.refContainer,
            style: { position: 'absolute', zIndex: 99 },
            disableEnforceFocus: true,
            keepMounted: true,
          }}>
          <Fragment>
            {props.commentDrawerOpen && (
              <Box
                sx={{
                  width: props.width,
                  height: '100%',
                  padding: '60px 8px 8px',
                }}>
                <Box
                  sx={{
                    height: '100%',
                    gap: 1,
                    display: 'flex',
                    flexDirection: 'column',
                  }}>
                  <Box
                    sx={{
                      padding: 1,
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 'bold',
                      }}>
                      {t('Comments')}
                    </Typography>
                    <Box flex={1} />
                    <IconButton onClick={props.handleCommentDrawerClick}>
                      <ArrowForwardIosIcon />
                    </IconButton>
                  </Box>
                  <Divider />
                  <Paper
                    sx={{
                      flex: 1,
                      overflowX: 'hidden',
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                    <Box
                      sx={{
                        overflowY: 'auto',
                        padding: 3,
                        gap: 2,
                        flex: 1,
                      }}>
                      {isLoading && (
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}>
                          <CircularProgress />
                        </Box>
                      )}
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 1,
                        }}>
                        {comments.map((c) => (
                          <Box sx={{ borderRadius: '5px', marginTop: '15px', boxShadow: 2 }}>
                            <CommentItemTitle
                              backgroundColor={
                                c.commentType === 'Approve'
                                  ? '#87C458'
                                  : c.commentType === 'Reject'
                                  ? '#FF5A5A'
                                  : c.commentType === 'Cancel'
                                  ? '#878686'
                                  : '#E8E8E8'
                              }>
                              <Grid
                                container
                                spacing={2}
                                direction="row"
                                justifyContent="space-between"
                                alignItems="baseline">
                                <Grid item>
                                  <Typography fontWeight="bold">{c.commentType}</Typography>
                                </Grid>
                                <Grid item>
                                  <Typography fontWeight="bold" textAlign="left">
                                    Status: {c.formStatus.formStatusName}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </CommentItemTitle>
                            <CommentItemContent>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                }}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    minHeight: '68px',
                                    padding: '10px 20px',
                                    alignItems: 'center',
                                    gap: '8px',
                                    position: 'relative',
                                    cursor: 'pointer',
                                  }}>
                                  <Box
                                    component="img"
                                    alt="Varadise"
                                    src={avatar}
                                    sx={{ width: '2.8rem', height: '2.8rem', borderRadius: '50%' }}
                                  />

                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'baseline',
                                      flex: 1,
                                      width: '100%',
                                    }}>
                                    <Box className={commonStyles.fillBox}>
                                      <Box sx={{ display: 'flex' }}>
                                        <Typography
                                          sx={{
                                            fontSize: 10,
                                          }}>
                                          {GetValueWithKey(
                                            c.createdBy,
                                            props.globalState.userMetaList ?? [],
                                          )}
                                        </Typography>
                                        <Box sx={{ flex: 1 }} />
                                        <Typography
                                          sx={{
                                            fontSize: 10,
                                          }}>
                                          {moment(c.createdAt).format()}
                                        </Typography>
                                      </Box>
                                      <Typography>{c.comment}</Typography>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </CommentItemContent>
                          </Box>
                        ))}
                      </Box>
                    </Box>
                    <CommentInput
                      formOnSubmitComment={async (comment: string) => {
                        if (props.formOnSubmitComment) {
                          await props.formOnSubmitComment(comment)
                          reloadComments()
                        }
                      }}
                    />
                  </Paper>
                </Box>
              </Box>
            )}
          </Fragment>
        </Drawer>
      )
    }

    return (
      <Fragment>
        <IconItem>
          <IconButton
            className={commonStyles.svgIcon}
            disabled={props.disableComment}
            data-disabled={props.disableComment}
            onClick={handleCommentDrawerClick}>
            <img src="/img/chat.svg" alt="comment" />
          </IconButton>
        </IconItem>
        <CommentDrawer
          refContainer={() => containerRef.current}
          commentDrawerOpen={commentDrawerOpen}
          handleCommentDrawerClick={handleCommentDrawerClick}
          formOnLoadCommentList={props.formOnLoadCommentList}
          globalState={globalState}
          formOnSubmitComment={props.formOnSubmitComment}
          width={matchDownMd ? '100%' : 500}
        />
      </Fragment>
    )
  }

  //#region form history
  const HistoryItem = () => {
    const [formHistoryDrawerOpen, setFormRemainingItemDrawerOpen] = useState<boolean>(false)
    const [formHistories, setFormHistories] = useState<FormHistory[]>([])
    const reloadFormHistories = () => {
      if (props.formOnLoadFormHistoryList) {
        props
          .formOnLoadFormHistoryList()
          .then((formHistoryList) => {
            setFormHistories(formHistoryList.filter((x) => x.status))
          })
          .catch((err) => {
            console.error('Error getting form histories from form', err)
            setFormHistories([])
          })
      } else {
        setFormHistories([])
      }
    }

    const handleFormHistoryDrawerClick = () => {
      setFormRemainingItemDrawerOpen((open) => !open)
      if (formHistoryDrawerOpen) {
        setFormHistories([])
      } else {
        reloadFormHistories()
      }
    }

    const FormHistoryDrawer = (props: {
      refContainer?: Element | (() => Element | null) | null | undefined
      formHistoryDrawerOpen: boolean
      handleFormHistoryDrawerClick: () => void
      formHistories: FormHistory[]
      width: number | string
      globalState: GlobalStateProps
    }) => {
      const { t } = useTranslation()
      const FormStepConnector = styled(StepConnector)(({ theme }) => ({
        [`& .${stepConnectorClasses.line}`]: {
          margin: 'auto auto auto 12.5px',
        },
      }))

      const FormStepIconRoot = styled('div')<{
        state: { first?: boolean }
      }>(({ state }) => ({
        zIndex: 1,
        backgroundColor: '#e6e6e6',
        width: state.first ? 50 : 30,
        height: state.first ? 50 : 30,
        marginLeft: state.first ? 0 : 10,
        marginRight: state.first ? 0 : 10,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
      }))

      const FormStepComponent = (props: StepIconProps) => {
        return (
          <FormStepIconRoot state={{ first: props.active === true }}>
            {String(props.icon)}
          </FormStepIconRoot>
        )
      }

      return (
        <Drawer
          anchor="right"
          open={props.formHistoryDrawerOpen}
          onClose={props.handleFormHistoryDrawerClick}
          PaperProps={{ style: { position: 'absolute', boxShadow: 'none', zIndex: 99 } }}
          BackdropProps={{
            style: { position: 'absolute', backgroundColor: 'transparent', zIndex: 99 },
          }}
          ModalProps={{
            container: props.refContainer,
            style: { position: 'absolute', zIndex: 99 },
            disableEnforceFocus: true,
            keepMounted: true,
          }}>
          <Fragment>
            {props.formHistoryDrawerOpen && (
              <Box
                sx={{
                  width: props.width,
                  height: '100%',
                  padding: '60px 8px 8px',
                }}>
                <Box
                  sx={{
                    height: '100%',
                    gap: 1,
                    display: 'flex',
                    flexDirection: 'column',
                  }}>
                  <Box
                    sx={{
                      padding: 1,
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: 'bold',
                      }}>
                      {t('common:buttons.histories')}
                    </Typography>
                    <Box flex={1} />
                    <IconButton onClick={props.handleFormHistoryDrawerClick}>
                      <ArrowForwardIosIcon />
                    </IconButton>
                  </Box>
                  <Divider />
                  <Paper
                    sx={{
                      flex: 1,
                      overflow: 'hidden',
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                    <Box
                      sx={{
                        overflow: 'auto',
                        padding: 3,
                        gap: 2,
                      }}>
                      <Stepper orientation="vertical" connector={<FormStepConnector />}>
                        {props.formHistories.map((formHistory, fhIndex) => {
                          return (
                            <Step key={`drawer-form-history-${fhIndex}`}>
                              <StepLabel
                                StepIconComponent={FormStepComponent}
                                StepIconProps={{ icon: props.formHistories.length - fhIndex }}>
                                <Box>
                                  <Typography
                                    sx={{
                                      color: 'black',
                                    }}>
                                    {formHistory.statusName}
                                  </Typography>
                                  <Typography sx={{ color: 'black', fontSize: 9 }}>
                                    {`${GetValueWithKey(
                                      formHistory.createdBy,
                                      props.globalState.userMetaList ?? [],
                                    )}`}
                                  </Typography>
                                  <Typography sx={{ color: 'black', fontSize: 9 }}>
                                    {formHistory.createdAt
                                      ? moment(formHistory.createdAt).format()
                                      : ''}
                                  </Typography>
                                </Box>
                              </StepLabel>
                            </Step>
                          )
                        })}
                      </Stepper>
                    </Box>
                  </Paper>
                </Box>
              </Box>
            )}
          </Fragment>
        </Drawer>
      )
    }

    return (
      <Fragment>
        <IconItem>
          <IconButton className={commonStyles.svgIcon} onClick={handleFormHistoryDrawerClick}>
            <img src="/img/history.svg" alt="history" />
          </IconButton>
        </IconItem>
        <FormHistoryDrawer
          refContainer={() => containerRef.current}
          formHistoryDrawerOpen={formHistoryDrawerOpen}
          handleFormHistoryDrawerClick={handleFormHistoryDrawerClick}
          formHistories={formHistories}
          globalState={globalState}
          width={matchDownMd ? '100%' : 500}
        />
      </Fragment>
    )
  }

  const RemainingItem = () => {
    const [formRemaingingItemDrawerOpen, setFormRemainingItemDrawerOpen] = useState<boolean>(false)

    const handleFormRemainingItemDrawerClick = () => {
      setFormRemainingItemDrawerOpen((open) => !open)
    }

    const FormRemainingItemDrawer = (props: {
      refContainer?: Element | (() => Element | null) | null | undefined
      formRemaingingItemDrawerOpen: boolean
      handleFormRemainingItemDrawerClick: () => void
      width: number | string
      globalState: GlobalStateProps
      onExportDocument?: (event: any) => void
    }) => {
      const { t } = useTranslation()

      return (
        <Drawer
          anchor="right"
          open={props.formRemaingingItemDrawerOpen}
          onClose={props.handleFormRemainingItemDrawerClick}
          PaperProps={{ style: { position: 'absolute', boxShadow: 'none', zIndex: 99 } }}
          BackdropProps={{
            style: { position: 'absolute', backgroundColor: 'transparent', zIndex: 99 },
          }}
          ModalProps={{
            container: props.refContainer,
            style: { position: 'absolute', zIndex: 99 },
            disableEnforceFocus: true,
            keepMounted: true,
          }}>
          <Fragment>
            {props.formRemaingingItemDrawerOpen && (
              <Box
                sx={{
                  width: props.width,
                  height: '30%',
                  padding: '60px 8px 8px',
                }}>
                <Box
                  sx={{
                    height: '100%',
                    gap: 1,
                    display: 'flex',
                    flexDirection: 'column',
                  }}>
                  {/*  <IconButton onClick={props.handleFormRemainingItemDrawerClick}>
                    <ArrowForwardIosIcon />
                  </IconButton> */}

                  <Divider />
                  <Paper
                    sx={{
                      flex: 1,
                      overflow: 'hidden',
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                    <Box
                      sx={{
                        padding: 2,
                      }}>
                      <Button onClick={props.onExportDocument}>
                        <FileDownloadIcon />
                        <Typography>Export</Typography>
                      </Button>
                    </Box>
                  </Paper>
                </Box>
              </Box>
            )}
          </Fragment>
        </Drawer>
      )
    }

    return (
      <Fragment>
        <IconItem>
          <IconButton className={commonStyles.svgIcon}>
            <img src="/img/navigation-menu.svg" alt="more" />
          </IconButton>
        </IconItem>
        <FormRemainingItemDrawer
          refContainer={() => containerRef.current}
          formRemaingingItemDrawerOpen={formRemaingingItemDrawerOpen}
          handleFormRemainingItemDrawerClick={handleFormRemainingItemDrawerClick}
          globalState={globalState}
          width={matchDownMd ? '100%' : 140}
          onExportDocument={props.onExportDocument}
        />
      </Fragment>
    )
  }

  const FormSwitcher = () => {
    // const { control, trigger } = useFormContext()

    const { state: globalState } = useContext(GlobalContext)

    const data = props.control ? useWatch({ control: props.control }) : { error: 'missing control' }
    const onDebug = () => {
      if (props.trigger) {
        props.trigger!()
      }

      console.debug('[Form Data]', data, JSON.stringify(data))
      console.debug('[globalState]', globalState, JSON.stringify(data))
    }
    {
      console.debug('props.components', props.components)
      console.debug('props.components activeStep', activeStep, props.components[activeStep])
    }
    return (
      <Fragment>
        <FormDialog
          key={'dialog'}
          open={dialogContext.isOpen}
          onClose={handleDialogClose}
          toolbarStyle={dialogContext.toolbarStyle}
          title={dialogContext.title}
          buttons={dialogContext.buttons}
          children={dialogContext.children}
        />

        <Box
          sx={{
            display: 'flex',
            position: 'fixed',
            width: '-webkit-fill-available',
            backgroundColor: 'white',
            zIndex: 100,
          }}
          boxShadow="2">
          <IconItem>
            <IconButton className={commonStyles.svgIcon} onClick={handleBackBtn}>
              <img src="/img/prev.svg" alt="back" />
            </IconButton>
          </IconItem>
          <IconItem sx={{ flexGrow: 1 }}></IconItem>
          <Debugger active={process.env.REACT_APP_ENV === 'DEV'}>
            <IconItem>
              <IconButton className={commonStyles.svgIcon} onClick={onDebug}>
                <AdbIcon fontSize="medium" />
              </IconButton>
            </IconItem>
          </Debugger>
          <IconItem>
            <IconButton
              className={commonStyles.svgIcon}
              onClick={(event: any) => {
                if (props.onSubFormComplete) {
                  handleOnComplete()
                } else if (props.formOnSave) {
                  props.formOnSave(event)
                }
              }}
              data-disabled={
                props.disableSave ||
                props.disableSaveBtn ||
                props.disableSaveOnStep === activeStep ||
                props.endOfFlow
              }
              disabled={
                props.disableSave ||
                props.disableSaveBtn ||
                props.disableSaveOnStep === activeStep ||
                props.endOfFlow
              }>
              <img src="/img/save.svg" alt="save" />
            </IconButton>
          </IconItem>

          <IconItem>
            <IconButton
              className={commonStyles.svgIcon}
              disabled={props.disableDelete}
              data-disabled={props.disableDelete}
              onClick={handleDelete}>
              <img src="/img/trash.svg" alt="trash" />
            </IconButton>
          </IconItem>

          <IconItem>
            <IconButton className={commonStyles.svgIcon} onClick={handleContentDrawerClick}>
              <img src="/img/listing-number.svg" alt="list" />
            </IconButton>
          </IconItem>

          <CommentItem />

          <HistoryItem />

          <IconItem>
            <IconButton className={commonStyles.svgIcon}>
              <img src="/img/navigation-menu.svg" alt="more" />
            </IconButton>
          </IconItem>
        </Box>
        <Grid key={'swticher'}>
          <LoadingPopup open={props.isLoading || false} />

          <Box
            ref={containerRef}
            sx={{
              position: 'relative',
            }}>
            {props.components.length > 0 ? (
              <StyledBox>
                <PartTitleTypography
                  variant={matchDownMd ? 'h6' : 'h4'}
                  sx={{ fontWeight: 'bold', zIndex: '1 !important' }}>
                  {t(String.fromCharCode(partLetterNumRef.current), { ns: i18nAlphabetNS }) +
                    ' / ' +
                    props.components[activeStep]?.partTitle}
                </PartTitleTypography>
                {activeStep > props.components.length && activeStep <= 0 ? (
                  <Typography sx={{ fontWeight: 500 }}>Stepper fault</Typography>
                ) : (
                  props.components[activeStep]?.component
                )}

                <Grid>
                  <StyledDivider>{t('common:components.formSwitcher.endOfSection')}</StyledDivider>
                  <ButtonGroupBox>
                    <SwitcherButton
                      disabled={partLetterNumRef.current <= 65}
                      onClick={handleBack}
                      startIcon={<ArrowBackIosNewIcon />}>
                      {/* {t('Previous')}  */}
                      {t('common:buttons.previous')}
                    </SwitcherButton>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <SwitcherButton
                      endIcon={<ArrowForwardIosIcon />}
                      onClick={handleNext}
                      disabled={
                        props.components[activeStep]?.onNextDisabled ||
                        (activeStep === props.components.length - 1 && props.disableSave) ||
                        (activeStep === props.components.length - 1 && props.endOfFlow)
                      }>
                      {activeStep === props.components.length - 1
                        ? props.customCompleteLabel
                          ? t(props.customCompleteLabel?.[activeStep])
                          : t('common:buttons.complete')
                        : props.customNextLabel
                        ? t(props.customNextLabel)
                        : t('common:buttons.next')}
                    </SwitcherButton>
                  </ButtonGroupBox>
                </Grid>
              </StyledBox>
            ) : (
              <Fragment>no part components</Fragment>
            )}
          </Box>
          <CommentDrawer
            refContainer={() => containerRef.current}
            commentDrawerOpen={commentDrawerOpen}
            handleCommentDrawerClick={handleCommentDrawerClick}
            formOnLoadCommentList={props.formOnLoadCommentList}
            globalState={globalState}
            formOnSubmitComment={props.formOnSubmitComment}
            width={matchDownMd ? '100%' : 500}
          />
          <ContentDrawer
            refContainer={() => containerRef.current}
            contentDrawerOpen={contentDrawerOpen}
            handleContentDrawerClick={handleContentDrawerClick}
            contents={props.components.map((component) => ({
              title: component?.partTitle,
              disabled: component?.disabled,
            }))}
            onContentClick={(index) => {
              setActiveStep(index)
              let numref = 0
              props.components
                .filter((x, idx) => idx < index)
                .map((x) => {
                  if (!x.disabled) {
                    numref += 1
                  }
                })
              partLetterNumRef.current = 65 + numref
            }}
            currentStep={activeStep}
            width={matchDownMd ? '100%' : 500}
          />
          <FormHistoryDrawer
            refContainer={() => containerRef.current}
            formHistoryDrawerOpen={formHistoryDrawerOpen}
            handleFormHistoryDrawerClick={handleFormHistoryDrawerClick}
            formHistories={formHistories}
            globalState={globalState}
            width={matchDownMd ? '100%' : 500}
          />
        </Grid>
      </Fragment>
    )
  }

  return { FormSwitcher, handleOnComplete, handleNext, handleBack, activeStep }
}

export default useFormSwitcher
